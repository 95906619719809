// --------------------------------------------------
// Slide in menu on mobile
// --------------------------------------------------


let menuButton = document.getElementById('menu-button');
let menuModal = document.getElementById('menu-modal');
let closeButton = document.getElementById('close');

menuButton.addEventListener('click', () => {
  menuModal.classList.add('active');
  document.body.style.overflow = 'hidden';
});

closeButton.addEventListener('click', () => {
  menuModal.classList.remove('active');
  document.body.style.overflow = 'auto';
});
